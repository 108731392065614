import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  githubAuthApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';
import { CustomTechRadarApi } from './components/techRadar/customTechRadarApi';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { ragAiApiRef, RoadieRagAiClient } from '@roadiehq/rag-ai';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) => ScmAuth.forGithub(githubAuthApi),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(techRadarApiRef, new CustomTechRadarApi()),
  createApiFactory({
    api: ragAiApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, fetchApi, configApi, identityApi }) => {
      return new RoadieRagAiClient({
        discoveryApi,
        fetchApi,
        configApi,
        identityApi,
      });
    },
  }),
  /*ScmAuth.createDefaultApiFactory(),*/
];
